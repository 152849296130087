/**
 * @file Use this directive to fix a bug where touch devices don't emit click events.
 * One example of this bug is when using the jsplumb library on Canvas format.
 * See: https://linear.app/padlet/issue/ENG-4093#comment-839e2275
 *
 * Usage:
 *   Step 1: Add a class `fix-click-on-touch` to an element that you wish to enable the fix.
 *           This is usually a container element.
 *   Step 2: Selectively add the `v-emit-click-on-touch` directive to the elements that you
 *           want to emit click events when touched. Be sure to import this directive file
 *           and register it in the component first.
 *           There's also a `v-emit-click-on-touch:deep` version that will apply the fix
 *           to all direct children of the element.
 */

import { nextTick } from 'vue'
// Vue 3 directive type isn't compatible with how we register directives using Options API
// so we have to import the type from Vue 2
import type { DirectiveOptions } from 'vue/types/options'

type MouseEventHandler = (e: MouseEvent) => void
type PointerEventHandler = (e: PointerEvent) => void
type HTMLElementWithPointerHandlers = HTMLElement & {
  $_onPointerDown?: PointerEventHandler
  $_onPointerUp?: PointerEventHandler
}

// How quick a touch must be to be considered a click (ms)
const TOUCH_TIME_TO_BE_CLICK = 300

const registerEmittingClickOnTouch = (el: HTMLElementWithPointerHandlers): void => {
  let touchStartX = -1
  let touchStartY = -1
  let touchStartTimestamp = -1
  let isBeingTouched = false
  let isClickEmitted = false

  const onClick: MouseEventHandler = () => {
    // We need to check if click event is actually emitted
    // so that we don't need to emulate the click anymore
    isClickEmitted = true
  }

  const onPointerDown: PointerEventHandler = (e) => {
    if (e.pointerType !== 'touch' || isBeingTouched) return
    touchStartX = e.x
    touchStartY = e.y
    touchStartTimestamp = e.timeStamp
    isBeingTouched = true
  }

  const onPointerUp: PointerEventHandler = (e) => {
    // Since pointerup is fired before click, we need to delay it
    setTimeout(() => {
      if (isClickEmitted) {
        // If click is emitted, no need to emulate it
        isClickEmitted = false
        return
      }
      if (e.pointerType !== 'touch' || !isBeingTouched) return
      // A touch is considered a click if:
      // - The x, y coordinates stay the same
      // - The touch is no longer than 300ms
      const isInvalid = touchStartX === -1 || touchStartY === -1 || touchStartTimestamp === -1
      const isSameCoordinates = e.x === touchStartX && e.y === touchStartY
      const isWithinTimeLimit = e.timeStamp - touchStartTimestamp <= TOUCH_TIME_TO_BE_CLICK
      if (!isInvalid && isSameCoordinates && isWithinTimeLimit) {
        if (e.target instanceof HTMLElement) {
          e.target.click()
        } else {
          el.dispatchEvent(new Event('click', e))
        }
      }
      touchStartX = -1
      touchStartY = -1
      touchStartTimestamp = -1
      isBeingTouched = false
      isClickEmitted = false
    }, 0)
  }

  el.addEventListener('click', onClick)
  el.addEventListener('pointerdown', onPointerDown)
  el.addEventListener('pointerup', onPointerUp)

  el.$_onPointerDown = onPointerDown
  el.$_onPointerUp = onPointerUp
}

const removeEmittingClickOnTouch = (el: HTMLElementWithPointerHandlers): void => {
  if (el.$_onPointerDown != null) {
    el.removeEventListener('pointerdown', el.$_onPointerDown)
    el.$_onPointerDown = undefined
  }
  if (el.$_onPointerUp != null) {
    el.removeEventListener('pointerup', el.$_onPointerUp)
    el.$_onPointerUp = undefined
  }
}

const directive: DirectiveOptions = {
  bind: (el, binding): void => {
    // Use nextTick to make sure the element is already in the DOM
    void nextTick(() => {
      const isTouchDevice = document.documentElement.classList.contains('touchable')
      const isInsideFixArea = el.closest('.fix-click-on-touch') !== null
      if (!isTouchDevice || !isInsideFixArea) return

      const elements: HTMLElement[] = [el]
      const isDeep = binding.arg === 'deep'
      if (isDeep) {
        for (const child of el.children) {
          if (child instanceof HTMLElement) elements.push(child)
        }
      }

      elements.forEach(registerEmittingClickOnTouch)
    })
  },

  unbind: (el, binding): void => {
    const elements: HTMLElement[] = [el]
    const isDeep = binding.arg === 'deep'
    if (isDeep) {
      for (const child of el.children) {
        if (child instanceof HTMLElement) elements.push(child)
      }
    }
    elements.forEach(removeEmittingClickOnTouch)
  },
}

export default directive
